import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { WidgetCard as PortalWidgetCard } from '@jarvis/react-portal-additions'
import { Card, ProgressIndicator } from '@veneer/core'

export const WidgetCard = styled(PortalWidgetCard)`
  && {
    border-radius: ${primitives.layout.cornerRadius3}px !important;
    height: 360px;
    min-height: 360px;
    width: 100%;
    box-shadow: none;
  }
  > div {
    padding: 0 ${primitives.layout.size6}px 43px ${primitives.layout.size6}px;
  }
`
export const Divider = styled.hr<{ border?: string; margin?: string }>`
  border-top: ${({ border }) => border} solid rgba(33, 33, 33, 0.1);
  margin: ${({ margin }) => margin} !important;
  border-left-width: 0px;
  border-bottom-width: 0px;
  border-right-width: 0px;
`
export const Text = styled.span`
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size2};
  line-height: ${primitives.typography.lineHeight2};
  color: ${primitives.color.gray12};
  padding-left: 0;
  margin: auto;
`
export const IconText = styled.div`
  display: flex !important;
  align-items: center;
`

export const IconStyle = styled.div`
  padding-right: ${primitives.layout.cornerRadius2}px;
`
export const StyledDiv = styled.div`
  display: flex;
`
export const TextCount = styled.span`
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size4} !important;
  line-height: ${primitives.typography.lineHeight3};
  color: ${primitives.color.gray12};
  padding-left: 0;
  margin: auto;
`
export const BottomArea = styled.div`
  height: 52px;
  width: 100%;
  margin-top: 193px;
  border-top: 1px solid rgba(33, 33, 33, 0.05);
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
`
export const LoaderCard = styled(Card)`
  width: 100%;
  height: 360px;
  vertical-align: middle;
`
export const StyledLoader = styled(ProgressIndicator)`
  margin-top: 165px;
  div {
    margin: auto;
  }
`
