import {
  UserTenantMgtSvcClient,
  RelationshipsClient
} from '@jarvis/web-stratus-client'
import { User } from '../interfaces/userListInterface'

export const getUsersListFromApi = async (
  authStack,
  authProvider,
  orgSelector,
  useOrgedAuthProvider
): Promise<Array<User>> => {
  const baseAuthProvider = useOrgedAuthProvider
    ? authProvider.createOrgedAuthProvider()
    : authProvider

  const userClient = new UserTenantMgtSvcClient(authStack, baseAuthProvider)

  const tenantResourceId =
    orgSelector.getCurrentCustomer() || orgSelector.getOrgTenantId()

  const usersData = []

  try {
    const usrListResponse = await userClient.getUserWithTenantList(
      tenantResourceId,
      0
    )

    if (usrListResponse.status === 200) {
      usrListResponse.data.resourceList
        .filter(
          (item) => item.userTenantDetail?.tenantResourceId !== item.user?.idpId
        )
        .map((item) => {
          const user: User = {
            resourceId: item.user.resourceId,
            roleCategory: item.userTenantDetail.roleCategory,
            state: item.userTenantDetail.state
          }
          return user
        })
        .forEach((item) => {
          usersData.push(item)
        })
    }
  } catch (err) {
    console.log('Error loading User With Tenant List', err)
  }

  // For all invited usersData, role displayname should be "Invitation" and it will be updated to actual display name once they are active based on rolepermId
  try {
    const extListResponse = await userClient.getExternalUserList(
      tenantResourceId,
      0
    )

    if (extListResponse.status === 200) {
      extListResponse.data.resourceList
        .map((item) => {
          const user: User = {
            resourceId: item.resourceId,
            roleCategory: item.roleCategory,
            state: item.state
          }
          return user
        })
        .forEach((item) => {
          usersData.push(item)
        })
    }
  } catch (err) {
    console.log('Error loading External User List', err)
  }
  return usersData
}

export const isServiceCustomer = async (criterion) => {
  const criterionCheck = await criterion.check({
    type: 'scope',
    value: 'ws-hp.com/ecp/serviceCustomer.READ',
    options: {
      userContext: 'organization'
    }
  })

  return criterionCheck
}

export const getCustomerDetailsList = async (
  orgSelector,
  authStack,
  authProvider
) => {
  const tenantID = await orgSelector.getOrgTenantId()

  const options = {
    nodeId: tenantID
  }

  const customerMgtSvcClient = new RelationshipsClient(
    authStack,
    authProvider.createOrgedAuthProvider()
  )

  const response =
    await customerMgtSvcClient.getNodewithAdministersCrossUses(options)
  return response?.data
}
