import { createContext } from 'react'
import { PromiseReturnType } from '../../types/typeHandlers'
import { LocalizationType } from '@jarvis/shell-commons/dist/interface/v1/orgSelector/types'
import { MfePropsType } from '../../types/mfeProps'
import { InterfacesType, TranslatorFunctionType } from '../../types/shell'
import { TranslatorFunctionResourcePropType } from '@jarvis/shell-commons/dist/services/localizationService/ILocalizationTranslatorService'

export type ShellRootContextType = {
  children?: React.ReactNode
  interfaces: InterfacesType
  t: TranslatorFunctionType
  localizationResources?: TranslatorFunctionResourcePropType
} & MfePropsType
export type GlobalTranslateType = PromiseReturnType<
  LocalizationType['createTranslatorFunction']
>
export type ShellRootUseContextReturnType = ShellRootContextType & {
  globalTranslate: GlobalTranslateType
}

const ShellRootContext = createContext({} as ShellRootUseContextReturnType)

export default ShellRootContext
