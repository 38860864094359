import React, { useEffect, useState } from 'react'
import {
  getCustomerDetailsList,
  getUsersListFromApi,
  isServiceCustomer
} from '../../utils/api'
import {
  IconChevronRight,
  IconCircle,
  ListItem,
  Button,
  List
} from '@veneer/core'
import { getColor } from '../../utils/commonMethods'
import useShellRootContext from '../../contexts/ShellRoot/useShellRootContext'
import * as S from './styles'
import * as T from '../../App/types'

const CustomerUsersWidget = ({ ...props }: T.AppPropsType) => {
  const {
    t = (_key: string, defaultValue: string) => defaultValue,
    interfaces
  } = useShellRootContext()
  const navigation = interfaces?.v1?.navigation
  const mockProp = props?.mockProp
  const hideLoader = props?.hideLoader
  const properties = props?.properties
  const orgSelector = interfaces?.v1.orgSelector
  const authStack = interfaces?.v1?.app?.getAuthStack?.()
  const criterion = interfaces?.v1?.criterion || props.mockCriterion

  const useOrgedAuthProvider = properties?.useOrgedAuthProvider

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [totalUsersCount, setTotalUsersCount] = useState<number>(0)
  const [endUsersCount, setEndUsersCount] = useState<number>(0)
  const [adminCount, setAdminCount] = useState<number>(0)
  const [activeCount, setActiveCount] = useState<number>(0)
  const [pendingCount, setPendingCount] = useState<number>(0)
  const [expiredCount, setExpiredCount] = useState<number>(0)
  const [serviceCustomer, setServiceCustomer] = useState<boolean>(false)
  const [totalCustomersCount, setTotalCustomersCount] = useState<number>(0)

  const UserStatus = [
    {
      value: t('ecp-customers-users-status-widget.active', 'Active'),
      status: 'active'
    },
    {
      value: t('ecp-customers-users-status-widget.pending', 'Pending'),
      status: 'pending'
    },
    {
      value: t('ecp-customers-users-status-widget.expired', 'Expired'),
      status: 'expired'
    }
  ]

  const UserRoles = [
    {
      value: t('ecp-customers-users-status-widget.admins', 'Admins'),
      role: 'admin'
    }
  ]

  useEffect(() => {
    const authProvider = interfaces?.v2?.authProvider || {}

    const getServiceCustomer = async () => {
      const response = await isServiceCustomer(criterion)
      setServiceCustomer(mockProp ? true : useOrgedAuthProvider && !response)
      return response
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getUsers = async (): Promise<any> => {
      const response = await getUsersListFromApi(
        authStack,
        authProvider,
        orgSelector,
        useOrgedAuthProvider
      )
      setTotalUsersCount(response?.length)
      const enduserData = response?.filter(
        (item) => item.roleCategory.toLowerCase() !== 'admin'
      )
      setEndUsersCount(enduserData?.length || 0)
      const adminData = response?.filter(
        (item) => item.roleCategory.toLowerCase() === 'admin'
      )
      setAdminCount(adminData?.length)
      setActiveCount(
        adminData?.filter((item) => item.state.toLowerCase() === 'active')
          .length
      )
      setPendingCount(
        adminData?.filter((item) => item.state.toLowerCase() === 'pending')
          .length
      )
      setExpiredCount(
        adminData?.filter((item) => item.state.toLowerCase() === 'expired')
          .length
      )
      setIsLoading(false)
      return response
    }

    const retriveTenants = async () => {
      try {
        setIsLoading(true)
        const response = await getCustomerDetailsList(
          orgSelector,
          authStack,
          authProvider
        )
        setTotalCustomersCount(response.totalElements)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(true)
        console.log(e)
      }
    }

    if (!serviceCustomer) {
      getUsers()
    } else {
      retriveTenants()
    }

    getServiceCustomer()
  }, [
    authStack,
    criterion,
    interfaces?.v2?.authProvider,
    mockProp,
    orgSelector,
    serviceCustomer,
    useOrgedAuthProvider
  ])

  useEffect(() => {
    if (hideLoader) {
      setIsLoading(false)
    }
  }, [hideLoader])

  return (
    <>
      {serviceCustomer === false ? (
        <>
          {isLoading ? (
            <S.LoaderCard content={<S.StyledLoader />} />
          ) : (
            <S.WidgetCard
              data-testid="users-title"
              title={t('ecp-customers-users-status-widget.usersTitle', 'Users')}
              titleCounter={totalUsersCount?.toString()}
            >
              <List
                data-testid="User-list"
                gutter="0px"
              >
                <ListItem
                  data-testid="total-users-nav-button"
                  leadingArea={
                    <S.Text>
                      {t(
                        'ecp-customers-users-status-widget.endUsers',
                        'EndUsers'
                      )}
                    </S.Text>
                  }
                  trailingArea={
                    <S.StyledDiv>
                      <S.IconText>
                        <S.TextCount data-testid="total-users-count-text">
                          {endUsersCount}
                        </S.TextCount>
                        <IconChevronRight />
                      </S.IconText>
                    </S.StyledDiv>
                  }
                  onClick={() => {
                    navigation?.push('/users?status=enduser')
                  }}
                />
                <S.Divider
                  data-testid="divider"
                  border="3px"
                  margin=" 4px 0 4px 0"
                />
                <List
                  data-testid="filter-block-2-list-block"
                  id="filter-block-2-list-block"
                  className=""
                >
                  {UserRoles &&
                    UserRoles.map((user) => (
                      <ListItem
                        data-testid="admin-nav-button"
                        key={user.value}
                        leadingArea={<S.Text>{user.value}</S.Text>}
                        trailingArea={
                          <S.StyledDiv>
                            <S.IconText>
                              <S.TextCount data-testid="admin-count">
                                {adminCount}
                              </S.TextCount>
                              <IconChevronRight />
                            </S.IconText>
                          </S.StyledDiv>
                        }
                        onClick={() =>
                          navigation?.push(`/users?status=${user.role}`)
                        }
                      />
                    ))}
                </List>
                <S.Divider
                  border="1px"
                  margin="0"
                />
                <List
                  data-testid="filter-block-2-list-block"
                  id="filter-block-2-list-block"
                  className=""
                >
                  {UserStatus &&
                    UserStatus.map((user) => (
                      <ListItem
                        key={user.value}
                        data-testid={`${user.status}-user-nav-button`}
                        leadingArea={
                          <S.IconText>
                            <S.IconStyle>
                              <IconCircle
                                size={12}
                                filled
                                color={getColor(user.status)}
                              />
                            </S.IconStyle>
                            <S.Text
                              data-testid={`${user.status}-user-status-title`}
                            >
                              {user.value}
                            </S.Text>
                          </S.IconText>
                        }
                        trailingArea={
                          <S.StyledDiv>
                            <S.IconText>
                              <S.TextCount
                                data-testid={`${user.status}-user-status-count`}
                              >
                                {user.status === 'active'
                                  ? activeCount
                                  : user.status === 'expired'
                                    ? expiredCount
                                    : pendingCount}
                              </S.TextCount>
                              <IconChevronRight />
                            </S.IconText>
                          </S.StyledDiv>
                        }
                        onClick={() =>
                          navigation?.push(`/users?status=${user.status}`)
                        }
                      />
                    ))}
                </List>
              </List>
            </S.WidgetCard>
          )}
        </>
      ) : (
        <>
          {isLoading ? (
            <S.LoaderCard content={<S.StyledLoader />} />
          ) : (
            <S.WidgetCard
              data-testid="customers-title"
              title={t(
                'ecp-customers-users-status-widget.customersTitle',
                'Customers'
              )}
              titleCounter={totalCustomersCount.toString()}
            >
              <List
                data-testid="Customers-list"
                gutter="0px"
              >
                <ListItem
                  data-testid="total-customers-nav-button"
                  leadingArea={
                    <S.Text>
                      {t(
                        'ecp-customers-users-status-widget.totalCustomers',
                        'Total Customers'
                      )}
                    </S.Text>
                  }
                  trailingArea={
                    <S.StyledDiv>
                      <S.IconText>
                        <S.TextCount data-testid="total-customers-count-text">
                          {totalCustomersCount}
                        </S.TextCount>
                        <IconChevronRight />
                      </S.IconText>
                    </S.StyledDiv>
                  }
                  onClick={() => {
                    navigation?.push('/customers')
                  }}
                />
                <S.Divider
                  data-testid="divider"
                  border="3px"
                  margin=" 4px 0 4px 0"
                />
              </List>
              <S.BottomArea>
                <Button
                  className="detailsbutton"
                  trailingIcon={<IconChevronRight />}
                  appearance="tertiary"
                  disabled={true}
                >
                  {t(
                    'ecp-customers-users-status-widget.viewDetails',
                    'View Details'
                  )}
                </Button>
              </S.BottomArea>
            </S.WidgetCard>
          )}
        </>
      )}
    </>
  )
}

export default CustomerUsersWidget
