import React from 'react'
import UsersWidget from '../components/UsersWidget'
import { Container } from './styles'
import * as T from './types'

const App = ({ properties, ...props }: T.AppPropsType) => {
  return (
    <Container>
      <UsersWidget
        properties={properties}
        {...props}
      />
    </Container>
  )
}

export default App
